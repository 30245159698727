import { config, library, dom } from "@fortawesome/fontawesome-svg-core";

config.mutateApproach = "sync";

import { faCheckCircle as farCheckCircle } from "@fortawesome/free-regular-svg-icons";
import { faCheckCircle as fasCheckCircle } from "@fortawesome/free-solid-svg-icons";

library.add(farCheckCircle, fasCheckCircle);

dom.watch();
