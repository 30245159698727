// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import "../stylesheets/application.scss";

import { Popover } from "bootstrap";
import "@hotwired/turbo";
import * as ActiveStorage from "@rails/activestorage";

import "../channels";
import "../controllers";
import "../fontawesome";

ActiveStorage.start();

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

Popover.Default.allowList.a.push(/^data-[\w-]*$/i);

addEventListener("turbo:load", () => {
  document
    .querySelectorAll('[data-bs-toggle="popover"]')
    .forEach((element) => new Popover(element));
});

let scrollTop = 0;

addEventListener("turbo:submit-start", ({ target }) => {
  if (target.hasAttribute("data-turbo-preserve-scroll")) {
    scrollTop = document.scrollingElement.scrollTop;
  }
});

addEventListener("turbo:load", ({ target }) => {
  if (scrollTop) {
    document.scrollingElement.scrollTo(0, scrollTop);
  }

  scrollTop = 0;
});
